



























































































































































































import dayjs from 'dayjs'
import { Component, Vue } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  url as urlValidation,
  or,
  and,
} from 'vuelidate/lib/validators'
import controller, { OptionsNumber, OptionsString } from '@/app/ui/controllers/BannerController'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import BannerTextInput from '../../components/BannerTextInput/index.vue'
import BannerDropdown from '../../components/BannerDropdown/index.vue'
import BannerDatePicker from '../../components/BannerDatePicker/index.vue'
import ImageUpload from '@/app/ui/components/ImageUpload/index.vue'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    Modal,
    BannerTextInput,
    BannerDropdown,
    BannerDatePicker,
    LoadingOverlay,
    ImageUpload,
    ConfirmationIcon,
  },
})
export default class CreateBanner extends Vue {
  controller = controller
  maxCount = 30
  MAX_SIZE = 5000
  form = {
    type: [] as Record<string, any>,
    title: '',
    description: '',
    bannerImage: <File[] | Array<undefined>>[],
    url: '',
    order: [] as Record<string, any>,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
  }
  hasChanged = {
    type: false,
    title: false,
    description: false,
    bannerImage: false,
    url: false,
    order: false,
    startDate: false,
    endDate: false,
    startTime: false,
    endTime: false,
  }
  isImageValid = false
  successModal = false
  confirmationModal = false
  todayDate = dayjs().format('YYYY-MM-DD')

  created(): void {
    controller.getBannerTypes()
    controller.getOrderBanner()

    EventBus.$on(EventBusConstants.SAVE_BANNER_SUCCESS, () => {
      this.successModal = true
    })
  }

  @Validations()
  validations(): Record<string, unknown> {
    return {
      form: {
        type: { required },
        title: { required, maxLength: maxLength(70) },
        description: { maxLength: maxLength(50) },
        url: {
          url: and(
            required,
            or(urlValidation, (value: string) =>
              value.startsWith('lionparcel://app')
            )
          ),
        },
        order: { required },
        startDate: { required },
        endDate: { required },
        startTime: { required },
        endTime: { required },
      },
    }
  }

  get attachmentName(): string | null {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (this.form.bannerImage && this.form.bannerImage[0]) {
      return this.form.bannerImage[0].name
    } else {
      return null
    }
  }

  get bannerSource(): string {
    return URL.createObjectURL(this.form.bannerImage)
  }

  get orderOptions(): Array<OptionsNumber> {
    return controller.constants.order
  }

  get typeOptions(): OptionsString[] {
    const types = []
    if (controller.bannerType.length > 0) {
      // standard loop is performance wise
      for (let i = 0; i < controller.bannerType.length; i++) {
        types.push({
          label: controller.bannerType[i].id,
          value: controller.bannerType[i].id,
        })
      }
    }
    return types
  }

  private onFileChange($event: File) {
    this.form.bannerImage.splice(0, 1, $event)
    if ($event) {
      this.isImageValid = true
    } else {
      this.isImageValid = false
    }
  }

  private onRemoveImage(index: number) {
    this.form.bannerImage.splice(index, 1)
  }

  private onCreateBanner() {
    if (!this.$v.form.$invalid && this.isImageValid && this.form.bannerImage[0]) {
      const payload = {
        ...this.form,
        bannerImage: this.form.bannerImage[0]
      }
      controller.createBanner(payload)
      this.confirmationModal = false
    } else {
      Vue.notify({
        title: 'Create Banner',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }

    this.hasChanged = {
      type: true,
      title: true,
      description: true,
      bannerImage: true,
      url: true,
      order: true,
      startDate: true,
      endDate: true,
      startTime: true,
      endTime: true,
    }
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({ name: 'BannerPage' })
  }

  private formatDate(date: string) {
    return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm Z')
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.SAVE_BANNER_SUCCESS)
  }
}
